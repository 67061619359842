import * as Pluto from '@own/pluto_client';
import { Application } from '../../engine/Application';
import { load } from '../../engine/physics/AmmoLoader';
import { PlayerExternalApi } from '../externalApi/PlayerExternalApi';
import NetworkManager from '../../engine/network/NetworkManager';
import TransportPlutoBinary from '../../engine/network/TransportPlutoBinary';
import VideoObject from '../network/objects/VideoObject';
import AudioPlaceObject from '../network/objects/AudioPlaceObject';
import ChatsService from '../../engine/network/services/chats/dolby/Conference.service';
import AudioService from '../../engine/network/services/chats/dolby/Audio.service';
import VideoService from '../../engine/network/services/chats/dolby/Video.service';
import BaseScene, { BaseSceneLoadData } from '../scenes/BaseScene';
import ScenesRegistry from '../scenes/ScenesRegistry';
import { defaultAvatarName } from '../assets/DefaultAvatars';
import { UIApi } from '../externalApi/UIApi';
import CharacterObject from '../network/objects/CharacterObject';
import VideoVariable from '../network/variables/VideoVariable';
import AudioVariable from '../network/variables/AudioVariable';
import VideoComponent from '../components/VideoComponent';
import { ApiControllerScene } from '../scenes/ApiControllerScene';

export default class BootstrapService {
  public app?: Application;

  public playerApiInstance?: PlayerExternalApi;

  public uiApi?: UIApi;

  public plutoConfig?: Pluto.Client_config;

  public client?: Pluto.Client;

  public chatsService?: ChatsService;

  public errors: (string)[] = [];

  public scene: BaseScene | null = null;

  public registerScenes() {
    ScenesRegistry.addScene('apiControlledSpace', ApiControllerScene);
  }

  public createScene(sceneName: string): void {
    const SceneClass = ScenesRegistry.getScene(sceneName);
    if (!SceneClass) throw Error('Scene not found');
    this.scene = new SceneClass(sceneName, {
      configUrl: process.env.REACT_APP_SCENE_CONFIG_URL, // TODO: process this some other way
    });
    this.app = new Application({ xrEnabled: true });
    if (this.app) this.scene?.createSpaceGeneratorService(this.app);
  }

  public async prepareConfig(): Promise<void> {
    return this.scene?.prepareConfig();
  }

  public startScene(sceneName: string, sceneData: BaseSceneLoadData) {
    if (!this.app) throw Error('Application not found');
    if (!this.scene) throw Error(`${sceneName} not create`);
    // this.scene.createSpaceGeneratorService(this.app);
    return this.createApp()
      .then(() => this.createNetworkClient().catch((err) => {
        console.error(err);
        this.errors.push(`${err.messageWithoutCode || err.message || err}. Going offline`);
      }))
      .then(() => { if (this.client) return this.createNetworkManager(); })
      .then(() => { if (this.network) return this.connectToSessionFromUrl(); })
      .then(() => { if (this.network) return this.syncAfterJoin(); })
      .then(() => { if (this.network) return this.createChatsService(); })
      .then(() => this.initSession())
      .then(() => this.loadSceneAndRun(this.scene, sceneData));
  }

  public createApp() {
    return load.then(() => {
      if (!this.app) throw Error('app not found');
      this.playerApiInstance = new PlayerExternalApi({ app: this.app });
      this.uiApi = new UIApi(this.app);
      return this.app;
    });
  }

  public get assistant() {
    if (!this.app || !this.scene) return undefined;
    return this.scene.spaceGeneratorService?.assistant;
  }

  public createChatsService() {
    // TODO: create backend as proxy for access token
    this.chatsService = new ChatsService(
      process.env.REACT_APP_DOLBY_KEY ?? '',
      process.env.REACT_APP_DOLBY_SECRET ?? '',
    );
    const audioService = new AudioService(this.chatsService);
    const videoService = new VideoService(this.chatsService);
    this.chatsService
      .setAudioService(audioService)
      .setVideoService(videoService);
    this.app?.setChatsService(this.chatsService);
    return this.chatsService.initialize().then(() => this.chatsService?.audioService?.askPermissionsForMic());
  }

  protected promiseWithTimeout<PromiseValueType>(request: Promise<PromiseValueType>, message = '', timeout = 10000) {
    return new Promise<PromiseValueType>((resolve, reject) => {
      const timoutId = setTimeout(() => {
        reject(new Error(message));
      }, timeout);
      return request.then((arg) => {
        clearTimeout(timoutId);
        resolve(arg);
      });
    });
  }

  public createNetworkClient() {
    if (!this.app) throw Error('App not created');
    this.plutoConfig = {
      ws: { url: process.env.REACT_APP_RTC_SERVER_URL ?? '' },
      dc: {
        ordered: process.env.REACT_APP_RTC_SERVER_ORDERED ? process.env.REACT_APP_RTC_SERVER_ORDERED === 'true' : false,
        maxPacketLifeTime: process.env.REACT_APP_RTC_SERVER_MAX_PACKET_LIFE_TIME
          ? Number(process.env.REACT_APP_RTC_SERVER_MAX_PACKET_LIFE_TIME) : undefined,
        maxRetransmits: process.env.REACT_APP_RTC_SERVER_MAX_RETRANSMITS
          ? Number(process.env.REACT_APP_RTC_SERVER_MAX_RETRANSMITS) : 0,
      },
      pc: { iceServers: [{ urls: [process.env.REACT_APP_RTC_STUN_SERVER ?? ''] }] },
    };
    return this.promiseWithTimeout(Pluto.Client.create(this.plutoConfig).then((client) => {
      this.client = client;
      return client;
    }), 'Can\'t connect to multiplayer server');
  }

  public get network() {
    return this.app?.networkManager;
  }

  public createNetworkManager() {
    if (!this.client || !this.plutoConfig) throw new Error('Client not created');
    const plutoTransport = new TransportPlutoBinary(this.client, this.plutoConfig);
    const network = new NetworkManager(plutoTransport);
    this.app?.setNetworkManager(network);
  }

  public connectToSessionFromUrl(paramName = 'sessionId') {
    if (!this.network) throw Error('Network not created');
    const urlParam = new URLSearchParams(window.location.search).get(paramName);
    const roomId = typeof urlParam === 'string' ? Number(urlParam) : null;
    return this.network.createOrJointRoom(roomId).then(({ roomId: createdRoomId }) => {
      if (!this.network) throw Error('Network not created');
      if (createdRoomId !== roomId) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(paramName, String(createdRoomId));
        const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.pushState(null, '', newRelativePathQuery);
      }
      this.network.transport.listRoomConnections(createdRoomId).then(({ connectionIds }) => {
        console.log('connectionIds', connectionIds);
      });
      this.network.sessionStore.id = createdRoomId;
      return { roomId: createdRoomId };
    }).catch((e) => {
      console.error(e);
      this.errors.push(`${e.messageWithoutCode || e.message || e}. Going offline`);
      this.network?.stop();
      this.network?.transport.closeConnection();
      this.app?.setNetworkManager(null);
    });
  }

  public syncAfterJoin() {
    if (!this.network) throw Error('Network not created');
    return this.network.syncUsersInRoom(this.network.sessionStore.id).then(() => {

    });
  }

  public initSession() {
    if (!this.network || !this.app) return Promise.resolve();
    this.network.sessionStore.init(this.network);

    CharacterObject.register(this.network);
    VideoObject.register(this.network);
    VideoVariable.register(this.network, this.app);
    AudioPlaceObject.register(this.network);
    AudioVariable.register(this.network, this.app);

    this.network.run();
    this.network.finishInitialization();
    // initialize session
    return this.network?.sessionStore.isInit ? Promise.resolve() : new Promise<void>((resolve) => {
      // TODO: may stuck
      this.network?.sessionStore.events.on('onInit', () => {
        if (this.network) this.network.initialized = false;
        resolve();
      });
    });
  }

  public loadSceneAndRun(scene: BaseScene | null, sceneData: BaseSceneLoadData) {
    if (!this.app) throw Error('App not created');
    if (!scene) throw Error('Scene not create');
    this.app.run();
    return this.app.sceneManager.loadScene<BaseSceneLoadData>(scene, sceneData).then(() => {
      if (!this.app) throw Error('App not created');
      document.body.append(this.app.renderer.domElement);
    }).then(() => {
      if (!this.app) throw Error('App not created');
      // TODO: можно упростить и просто смотреться флаг session.isInitialized
      return sceneData.initSpace ? this.scene?.createOrJoinNetworkRoom(this.app, true) : undefined;
    });
  }

  public addUser(name: string, micActive: boolean, soundActive: boolean, camActive: boolean) {
    if (!this.network) return Promise.resolve(undefined);
    return this.network.sessionStore.tryToAddUser({
      id: this.network.networkId,
      name,
      activeRoomID: this.network.sessionStore.id,
      avatarName: this.app && this.scene
        ? this.scene.getSpaceGeneratorService(this.app).avatarsAssets.defaultAvatar : defaultAvatarName,
    })
      .then((data) => {
        console.log('addUser', data);
        return data;
      })
      .then(() => {
        if (!this.chatsService || !this.network) return undefined;
        return this.chatsService.connect({
          name,
          id: String(this.network.networkId),
        });
      })
      .then(() => {
        if (!this.chatsService || !this.network) return undefined;
        return this.chatsService.createAndJoinConference(String(this.network.sessionStore.id));
      })
      .then(() => {
        if (!this.chatsService) return Promise.resolve();
        const { audioService } = this.chatsService;
        if (audioService) {
          if (audioService.localAudioEnabled && !micActive) {
            audioService.localAudioEnabled = micActive;
          }
          audioService.localSoundEnabled = soundActive;
        }
        if (micActive) audioService?.enableAudio();
        if (camActive) this.chatsService?.videoService?.enableVideo();
        // this.chatsService?.videoService?.enableScreenShare();
      });
  }

  // FIXME: move somewhere
  public updateNetworkObjects() {
    if (!this.network) return;
    this.network.objects.forEach((obj) => {
      if (obj instanceof VideoObject || obj instanceof AudioPlaceObject) {
        this.network?.sendSuccessReceiveNewObject(obj);
      }
    });
  }

  public initVRSession() {
    if (!this.app) return Promise.resolve();
    const vr = this.app.vrSession;
    return vr.isSessionSupported().then(([supported, error]) => {
      const isOculus = /OculusBrowser/i.test(navigator.userAgent);
      // autostart VR only on oculus
      if (supported && isOculus) {
        vr.startSession();
      }
    });
  }

  public handleUserInteraction(soundActive?: boolean) {
    if (!this.app || !this.app.sceneManager.currentScene) return Promise.resolve(null);
    return this.app.sceneManager.currentScene.handleUserInteraction(this.app)
      .then(() => {
        if (!this.app || typeof soundActive === 'undefined') return;
        const { currentScene } = this.app.sceneManager;
        if (currentScene instanceof BaseScene) {
          currentScene.setSound(this.app, soundActive);
        }
      });
  }

  public reloadVideos() {
    if (!this.app || !this.app.sceneManager.currentScene) return;
    this.app.componentManager.getComponentsByType(VideoComponent).forEach((vc) => {
      vc.controller.update();
    });
  }

  public destroy() {
    this.app?.destroy();
  }
}

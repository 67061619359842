import { System } from '../../../engine/System';
import TopMenuComponent from '../../components/UI/TopMenu.component';
import WelcomeScreenComponent from '../../components/UI/WelcomeScreen.component';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { ButtonId } from '../../ui/enum/ButtonId';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import SceneLightMapsComponent from '../../components/SceneLightMaps.component';
import MainMenuComponent from '../../components/UI/MainMenu.component';
import BaseScene from '../../scenes/BaseScene';
import { TopMenuAdaptive } from '../../ui/adaptive';
import { UIBuilderSystem } from '../UIBuilder.system';
import ActionsMenuComponent from '../../components/UI/ActionsMenu.component';

export default class TopMenuSystem extends System {
  public onUpdate(ts: number) {
    this.app.componentManager.getComponentsByType(TopMenuComponent).forEach((component) => {
      this.handleWelcomeMode(component);
      this.handleEnabled(component);
      if (!component.enabled) return;
      this.handleActiveButtons(component);
    });
  }

  protected handleEnabled(component: ActionsMenuComponent) {
    const uiDocument = component.entity.getComponentOrFail(UIDocumentComponent);
    if (uiDocument.root) {
      uiDocument.enabled = component.enabled;
      uiDocument.root.visible = component.enabled;
    }
  }

  protected handleWelcomeMode(component: TopMenuComponent) {
    const welcomeComponent = this.app.componentManager.getComponentsByType(WelcomeScreenComponent)[0];
    if (!welcomeComponent) return;
    const uiSystem = this.app.getSystem(UIBuilderSystem);
    if (!uiSystem) return;
    const adaptive = uiSystem.getAdaptiveServiceByComponent(component) as TopMenuAdaptive;
    component.enabled = !(welcomeComponent.enabled && adaptive.isMobile);
  }

  protected setIconState(doc: UIDocumentComponent, btnId: string, state: string, iconName = 'icon') {
    const btnElement = doc.getElementById(btnId);
    if (btnElement) {
      const iconElement = doc.getChildElementByName(btnElement, iconName);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (iconElement?.states.alt) iconElement?.setState(state);
    }
  }

  protected setButtonIcon(ui: UIDocumentComponent, buttonId: ButtonId, flag: boolean) {
    const btn = ui.getElementById(buttonId);
    if (btn) {
      const icon = ui.getChildElementByName(btn, 'icon');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (icon?.states.alt) icon?.setState(flag ? 'default' : 'alt');
    }
  }

  public handleActiveButtons(component: TopMenuComponent): void {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const { audioService, videoService } = this.app.chatsService || {};

    const dialogComponents = [
      ...this.app.componentManager.getComponentsByType(WelcomeScreenComponent),
      ...this.app.componentManager.getComponentsByType(MainMenuComponent),
    ];

    if (states[ButtonId.InfoButton]?.state === UIDocumentElementState.Active) {
      dialogComponents.forEach((c) => { c.enabled = false; });
      this.app.componentManager.getComponentsByType(WelcomeScreenComponent).forEach((wcomponent) => {
        wcomponent.enabled = true;
      });
    }

    if (states[ButtonId.MainButton]?.state === UIDocumentElementState.Active) {
      dialogComponents.forEach((c) => { c.enabled = false; });
      this.app.componentManager.getComponentsByType(MainMenuComponent).forEach((mainComponent) => {
        mainComponent.enabled = true;
      });
    }

    if (states[ButtonId.MicButton]?.state === UIDocumentElementState.Active) {
      audioService?.toggleAudio();
    }

    if (states[ButtonId.CamButton]?.state === UIDocumentElementState.Active) {
      videoService?.toggleVideo();
    }

    if (states[ButtonId.Sound]?.state === UIDocumentElementState.Active) {
      audioService?.toggleSound().then(() => {
        const { currentScene } = this.app.sceneManager;
        if (currentScene instanceof BaseScene) {
          currentScene.setSound(this.app, audioService?.localSoundEnabled ?? false);
        }
      });
    }

    this.setIconState(uIDocumentComponent, ButtonId.MicButton, audioService?.isAudioEnabled ? 'default' : 'alt');
    this.setIconState(uIDocumentComponent, ButtonId.Sound, audioService?.isSoundEnabled ? 'default' : 'alt');

    this.setButtonIcon(uIDocumentComponent, ButtonId.CamButton, !!videoService?.isVideoEnabled);

    const micBtn = uIDocumentComponent.getElementById(ButtonId.MicButton);
    if (micBtn) {
      const micIcon = uIDocumentComponent.getChildElementByName(micBtn, 'icon');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (micIcon?.states.alt) micIcon?.setState(audioService?.isAudioEnabled ? 'default' : 'alt');
    }

    if (states[ButtonId.DayNightButton]?.state === UIDocumentElementState.Active) {
      this.app.componentManager.getComponentsByType(SceneLightMapsComponent)
        .forEach((lightComponent: SceneLightMapsComponent) => {
          lightComponent.enableNextPreset();
          const button = uIDocumentComponent.getElementById(ButtonId.DayNightButton);
          if (button) {
            const element = uIDocumentComponent.getChildElementByName(button, 'icon');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            element?.setState(element?.currentState === 'default' ? 'alt' : 'default');
          }
        });
    }
  }
}
